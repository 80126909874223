/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 var infoWindow = new google.maps.InfoWindow({
 content		: ''
 });
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // if($('.flex-container').length === 1) { // adjust padding to flex content if only one flex-container exists
        //   $('.flex-container').css('padding', '100px 0 100px 0');
        // }

        // if($('.flex-container').length === 0) { // adjust padding to flex content if no flex-container exists
        //   $('body.archive .wrap.container-fluid>.container').css('padding', '60px 15px');
        // }


        // check if flex columns containers' col-md are wrapped or not
        // function wrapped() {
        //     var offset_top_prev;
        //
        //     $('.flex-container .col-md').each(function() {
        //        var offset_top = $(this).offset().top;
        //
        //       if (offset_top > offset_top_prev) {
        //          $(this).addClass('wrapped');
        //       } else if (offset_top === offset_top_prev) {
        //          $(this).removeClass('wrapped');
        //       }
        //
        //       offset_top_prev = offset_top;
        //    });
        // }
        // wrapped();
        //
        // $(window).resize(function() {
        //    wrapped();
        // });


        // pagination classes
        $('a.next').closest('li').addClass('next-item');
        $('a.prev').closest('li').addClass('prev-item');
        $('ul.pagination li:not(.next-item):last').addClass('last-page-item');
        $('ul.pagination li:not(.prev-item):first').addClass('first-page-item');

        // Search button in header:
        $("#searchbtn").on("click", function(e){
            $(this).toggleClass("expanded");
            $(this).parent().find('.searchformreveal').slideToggle();
        });

        // Video in hero of home page:
        // $('.btn-watchvideo').click(function(){
        //   if ( $('.home-video').css('display') === 'none' ){
        //     $(this).siblings('.home-video').fadeToggle();
        //     $('.home-video .video-inner .embed-responsive-item')[0].src += "&autoplay=1";
        //   }
        // });
        $('.closevideo').click(function(){
            var videoURL = $('.home-video .video-inner .embed-responsive-item').prop('src');
            videoURL = videoURL.replace("&autoplay=1", "");
            $('.home-video .video-inner .embed-responsive-item').prop('src','');
            $('.home-video .video-inner .embed-responsive-item').prop('src',videoURL);
            $(this).siblings('.home-video').fadeToggle();
        });


        // $('#searchbtn').click(function(){
        //   $( ".searchformreveal" ).slideToggle( "slow", function() {
        //     // Animation complete.
        //   });
        // });


        // primary nav hover dropdowns
        $('body').on('mouseenter mouseleave','.dropdown',function(e){
          var _d=$(e.target).closest('.dropdown');_d.addClass('show');
          setTimeout(function(){
            _d[_d.is(':hover')?'addClass':'removeClass']('show');
          },300);
        });

        // move phone number into header of get a quote form sidebar + remove from footer.quoteform
        $('.affixed-form .sidebar-phone').appendTo('.affixed-form .gform_heading');
        $('footer.quoteform .sidebar-phone').css('display', 'none');

        // Breakout containers: // width works but needs centering to browser window...
        // if can get this to work, won't need any css
        // $(window).resize(function(){
        //     confluidWidth = $('container-fluid').width();
        //     conWidth = $('.container-fluid > .container').width();
        //     diffWidth = confluidWidth - conWidth;
        //     marginleft = diffWidth * -1;
        //
        //     $(".breakout-container").width($(window).width());
        //     //$('.breakout-container').css('margin-left', marginleft );
        //
        // });

        // function gfieldDesc () {
        $(".gfield_description").each(function(){
            if($(this).html()==="&nbsp;") {
              $(this).addClass('empty-gfield-desc');
            } //if it only contains &nbsp;
        });
        // }

        if ($(window).width() > 576) {
          $('.empty-gfield-desc').css('display', 'block');
        } else {
          $('.empty-gfield-desc').css('display', 'none');
        }
        $(window).resize( function(){
          if ($(window).width() > 576) {
            $('.empty-gfield-desc').css('display', 'block');
          } else {
            $('.empty-gfield-desc').css('display', 'none');
          }
        });


        // Matt's jQuery
        $(".home .flex-container.columns-container .col-md a.btn.btn-primary.btn-lightbg").attr('href', '#req');

        // Select all links with hashes
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href^="#flexslider"]')
          .not('[href="#0"]')
          .not('[href="#carouselControls"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
              location.hostname === this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

          $('.page-id-4 .col-md-5.fc-image-container').removeClass('col-md-5').addClass('col-md-6');


          // For news slider
          if ($(window).width() < 992) {
            var $news1 = $('.col.h-100.d-inline-block:nth-child(1)');
            var $news2 = $('.col.h-100.d-inline-block:nth-child(2)');
            var $news3 = $('.col.h-100.d-inline-block:nth-child(3)');

            $($news1).appendTo('#newsitem1');
            $($news2).appendTo('#newsitem2');
            $($news3).appendTo('#newsitem3');
          } else {
            $('#carouselControls').hide();
          }



          // For location boxes
          var $locationBox = $('.post-type-archive-locations .location-box.col-md-3.w-100.locations.type-locations.status-publish.hentry');
          var $locationBoxHidden = $('.post-type-archive-locations .location-box.col-md-3.w-100.locations.type-locations.status-publish.hentry:nth-child(n+6)');
          var $locationShowButton = $('<div class="row"><div class="col-12"><button class="btn btn-primary btn-lightbg" id="location-show-button" style="display:block;margin:0 auto;">Show more</button></div></div>');

          if ($(window).width() < 768) {
            $($locationShowButton).appendTo('.locations-list-container');
            $($locationBoxHidden).hide();


            $('#location-show-button').click(function() {
              $($locationBoxHidden).toggle(200);
              $(this).text(function(i, text){
                  return text === "Show less" ? "Show more" : "Show less";
              });
            });
          } else {
            $($locationBox).show();
          }



          // For FAQ "read more"

            $('div.minimize').each(function(){
              if($(this).height() > 95) {
                $(this).addClass('truncate');
                $(this).parent().append('<a href="javascript:void(0)" class="more">Read More</a>');
              }
            });

            $(document).on('click', '.entry-excerpt a.more', function() {
                event.preventDefault();
                $(this).prev('.truncate').removeClass('truncate');
                $(this).removeClass('more').addClass('less').text('Read Less');
            });

            $(document).on('click', '.entry-excerpt a.less', function(event) {
                event.preventDefault();
                $(this).prev().addClass('truncate');
                $(this).removeClass('less').addClass('more').text('Read More');
            });



        // jQuery.fn.extend({
        //
        //   followTo: function (elem, marginTop) {
        //     var $this = $(this);
        //     var $initialOffset = $this.offset().top;
        //
        //     getVisible = function(element) {
        //       var $el = $('footer.content-info'),
        //       scrollTop = $(this).scrollTop(),
        //       scrollBot = scrollTop + $(this).height(),
        //       elTop = $el.offset().top,
        //       elBottom = elTop + $el.outerHeight(),
        //       visibleTop = elTop < scrollTop ? scrollTop : elTop,
        //       visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        //       return visibleBottom - visibleTop;
        //     };
        //     getVisible2 = function(element) {
        //       var $el = $('footer.quoteform'),
        //       scrollTop = $(this).scrollTop(),
        //       scrollBot = scrollTop + $(this).height(),
        //       elTop = $el.offset().top,
        //       elBottom = elTop + $el.outerHeight(),
        //       visibleTop = elTop < scrollTop ? scrollTop : elTop,
        //       visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        //       return visibleBottom - visibleTop;
        //     };
        //
        //     setPosition = function() {
        //       if ( $(window).scrollTop() > $initialOffset ) {
        //         if ( elem.offset().top > ( $(window).scrollTop() + $this.outerHeight() + marginTop ) ) {
        //           $this.css({ position: 'fixed', top: marginTop });
        //         }
        //         if ( elem.offset().top <= ( $(window).scrollTop() + $this.outerHeight() + marginTop ) ) {
        //           vistotal = getVisible() + getVisible2();
        //           pushit = marginTop - vistotal;
        //           $this.css({ position: 'fixed', top: pushit });
        //
        //         }
        //       }
        //       if ( $(window).scrollTop() <= $initialOffset ) {
        //         $this.css({ position: 'fixed', top: 200 });
        //       }
        //     };
        //
        //     $(window).resize( function(){
        //       if ($(window).width() > 1200) {
        //         setPosition();
        //       } else {
        //         $('.affixed-form').css( "position", "relative");
        //       }
        //     });
        //     $(window).scroll( function(){
        //       if ($(window).width() > 1200) {
        //         setPosition();
        //       } else {
        //         $('.affixed-form').css( "position", "relative");
        //       }
        //      });
        //   }
        // });
        //
        //
        // if ($('.affixed-form').length){
        //   if($('body').hasClass('page-template-template-newsfooter') ) {
        //     // do nothing, see conditional for this page template in this file
        //   } else {
        //   $('.affixed-form').followTo( $('footer.quoteform'), 60);
        //   }
        // }

        // sidebar:
        var doc = $(document);

        function sidebarscroll() {
          if ($('.affixed-form').length){
            if ($(window).width() > 1200) {

              var sidebar = $('.affixed-form');
              var container = $('.page-header-inner .col-md-4');

              if( $('body').hasClass('page-template-template-newsfooter') ) {
                footer = $('.full-width-cta');

              } else if( $('body').hasClass('home') ) {
                footer = $('.acf-map-container');

              } else {
                footer = $('footer.quoteform');

              } // body class check

              scrollheight = footer.offset().top - 129; //minus header height


              var top = doc.scrollTop() + sidebar.height() + 129;
              var footerTop = footer.offset().top;
              var footerOffset = footerTop - top;
              // console.log("footer: " + footer);
              // console.log("docScrollTop: " + doc.scrollTop() );
              // console.log("top: " + top);
              // console.log("footerTop: " + footerTop);
              // console.log("footeroffset: " + footerOffset);
              // console.log("scrollheight: " + scrollheight);

              if (footerOffset < 0) {
                  container.addClass('sidebar-stop');
              } else {
                  container.removeClass('sidebar-stop');
              }

              var headerTop = $('.page-header-inner').offset().top - 60;
              var affixtop = doc.scrollTop();
              var headerOffset = headerTop - affixtop;
              // console.log("headerTop: " + headerTop);
              // console.log("affixtop: " + affixtop);
              // console.log("headeroffset: " + headerOffset);

              if (headerOffset < 0) {
                container.css('height', scrollheight);
                container.addClass('sidebar-scroll');
              } else {
                  container.css('height', 'auto');
                  container.removeClass('sidebar-scroll');

              }




            } else {
              $('.page-header-inner .col-md-4').removeClass('sidebar-scroll');
            }// window width check

          } // if affixed-form exists
        } // sidebarscroll function


        sidebarscroll();

        $(window).resize( function(){
          sidebarscroll();
        });

        doc.scroll(function () {
          sidebarscroll();
        });


        // doc.scroll(function () {
        //
        //
        // });








          // Video overlay button:
          var tag = document.createElement('script');
          tag.src = "https://www.youtube.com/iframe_api";
          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          $('.vid-overlay').click(function(){
            $(this).hide();
            // $(this).siblings('.embed-responsive-item').get(0).playVideo();

            $(this).siblings('.embed-responsive-item')[0].src += "&autoplay=1";
              // ev.preventDefault();

          });


          // add classes to sliders:
        // $('.carousel .carousel-indicators li:first-child').addClass('active');
        $('.carousel .carousel-inner .carousel-item:first-child').addClass('active');


          // flex column image (stretch height of row)
          // $('.columns-container').resize(function(){
          //    var divHeight = $(this).height();
          //    $('.fc-image-container').css('minHeight', divHeight+'px');
          // }
          // var divHeight = $('.columns-container').height();
          // $('.fc-image-container').css('minHeight', divHeight+'px');


          // View More / Less on ACF Latest History:
          $('.latest-history-container article.history:gt(8)').hide().next('.divider').hide(); //:gt() is 0 index based

          $('.viewmore-history').on('click', function(e) {
            e.preventDefault();

            //var visible = $("article.history[style='display:block']").length;
            var visible = $("article.history:visible").length;

            if(visible > 9) {
              $('.latest-history-container article.history:gt(8)').hide().next('.divider').hide();
              $(this).text('View More');
            } else {
              $('.latest-history-container article.history').show().next('.divider').show();
              $('.latest-history-container .divider:nth-of-type(3n+2)').hide(); // re-hide trailing dividers
              $(this).text('View Less');
            }
          });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired





      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_newsfooter': {
      init: function() {


        // jQuery.fn.extend({
        //
        //   followTo: function (elem, marginTop) {
        //     var $this = $(this);
        //     var $initialOffset = $this.offset().top;
        //
        //     getVisible = function(element) {
        //       var $el = $('footer.content-info'),
        //       scrollTop = $(this).scrollTop(),
        //       scrollBot = scrollTop + $(this).height(),
        //       elTop = $el.offset().top,
        //       elBottom = elTop + $el.outerHeight(),
        //       visibleTop = elTop < scrollTop ? scrollTop : elTop,
        //       visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        //       value = visibleBottom - visibleTop;
        //       if (value > 0) {
        //         return value;
        //       } else {
        //         return 0;
        //       }
        //     };
        //     getVisible2 = function(element) {
        //       var $el = $('footer.quoteform'),
        //       scrollTop = $(this).scrollTop(),
        //       scrollBot = scrollTop + $(this).height(),
        //       elTop = $el.offset().top,
        //       elBottom = elTop + $el.outerHeight(),
        //       visibleTop = elTop < scrollTop ? scrollTop : elTop,
        //       visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        //       value = visibleBottom - visibleTop;
        //       if (value > 0) {
        //         return value;
        //       } else {
        //         return 0;
        //       }
        //     };
        //     getVisible3 = function(element) {
        //       var $el = $('.full-width-news'),
        //       scrollTop = $(this).scrollTop(),
        //       scrollBot = scrollTop + $(this).height(),
        //       elTop = $el.offset().top,
        //       elBottom = elTop + $el.outerHeight(),
        //       visibleTop = elTop < scrollTop ? scrollTop : elTop,
        //       visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
        //       value = visibleBottom - visibleTop;
        //       if (value > 0) {
        //         return value;
        //       } else {
        //         return 0;
        //       }
        //     };
        //
        //     setPosition = function() {
        //       if ( $(window).scrollTop() > $initialOffset ) {
        //         if ( elem.offset().top > ( $(window).scrollTop() + $this.outerHeight() + marginTop ) ) {
        //           $this.css({ position: 'fixed', top: marginTop });
        //         }
        //         if ( elem.offset().top <= ( $(window).scrollTop() + $this.outerHeight() + marginTop ) ) {
        //           vistotal = getVisible() + getVisible2() + getVisible3();
        //           console.log(vistotal);
        //           pushit = marginTop - vistotal;
        //           $this.css({ position: 'fixed', top: pushit });
        //
        //         }
        //       }
        //       if ( $(window).scrollTop() <= $initialOffset ) { // if scrolled to top of page
        //         $this.css({ position: 'fixed', top: 200 });
        //       }
        //     };
        //
        //     $(window).resize( function(){
        //       if ($(window).width() > 1200) {
        //         setPosition();
        //       } else {
        //         $('.affixed-form').css( "position", "relative");
        //       }
        //     });
        //     $(window).scroll( function(){
        //       if ($(window).width() > 1200) {
        //         setPosition();
        //       } else {
        //         $('.affixed-form').css( "position", "relative");
        //       }
        //      });
        //   }
        // });
        //
        //
        // if ($('.affixed-form').length){
        //
        //   $('.affixed-form').followTo( $('.full-width-news'), 60);
        //
        // }







      }
    },
    'post_type_archive_careers': {
      init: function() {




      }
    },
    'post_type_archive_locations': {
      init: function() {












      },
      finalize: function() {

      }
    },
    'faq': {
      init: function() {





      },
      finalize: function() {

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
